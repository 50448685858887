import { Record } from 'immutable';

// Statuses
const STATUS_IN_PROGRESS = 0; // In Progress (Application)
const STATUS_PENDING_DECISION = 1; // Pending Decision (Selection)
const STATUS_APPROVED_NO_EMAIL = 4; // Approved - No email (Approved)
const STATUS_APPROVED_ONBOARDING_SESSION = 6; // Approved - Onboarding Session (Approved)
const STATUS_UNMATCHED = 7; // Un-matched (Matching)
const STATUS_MATCHED = 8; // Matched (Matching)
const STATUS_INCUBATING = 9; // Incubating (Incubation)
const STATUS_ALUMNI = 10; // Alumni (Alumni)

export const getStatusDisplay = (t, status) => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return t('In Progress');
    case STATUS_PENDING_DECISION:
      return t('Pending Decision');
    case STATUS_APPROVED_NO_EMAIL:
      return t('Manual Follow-Up');
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return t('Onboarding');
    case STATUS_UNMATCHED:
      return t('Unmatched');
    case STATUS_MATCHED:
      return t('Matched');
    case STATUS_INCUBATING:
      return t('Incubating');
    case STATUS_ALUMNI:
      return t('Alumni');
    default:
      return t('Application Incomplete');
  }
};

// Program Management stages
export const PM_STATUS_APPLICATION = 'application';
export const PM_STATUS_SELECTION = 'selection';
export const PM_STATUS_APPROVED = 'approved';
export const PM_STATUS_MATCHING = 'matching';
export const PM_STATUS_INCUBATION = 'incubating';
export const PM_STATUS_ALUMNI = 'alumni';
export const PM_STATUS_DROPPED = 'dropped';
export const PM_STATUS_REJECTED = 'rejected';

export const getPMStageByApplicationStatus = status => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return PM_STATUS_APPLICATION;
    case STATUS_PENDING_DECISION:
      return PM_STATUS_SELECTION;
    case STATUS_APPROVED_NO_EMAIL:
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return PM_STATUS_APPROVED;
    case STATUS_UNMATCHED:
    case STATUS_MATCHED:
      return PM_STATUS_MATCHING;
    case STATUS_INCUBATING:
      return PM_STATUS_INCUBATION;
    case STATUS_ALUMNI:
      return PM_STATUS_ALUMNI;
    default:
      return 'Unknown status';
  }
};

// Rejected reasons
const REJ_TOO_EARLY_STAGE = 0;
const REJ_TOO_MATURE = 1;
const REJ_PROBLEM_WITH_PROJECT = 2;
const REJ_PROBLEM_WITH_MENTOR = 3;
const REJ_PROBLEM_WITH_LANG = 4;
const REJ_PROBLEM_WITH_CONNECTIVITY = 5;
const REJ_BECAME_INACTIVE = 6;
const REJ_OTHER = 7;

export const getRejectedStatusDisplay = (t, rejectedReason) => {
  switch (rejectedReason) {
    case REJ_TOO_EARLY_STAGE:
      return t('Too Early-Stage');
    case REJ_TOO_MATURE:
      return t('Too Mature');
    case REJ_PROBLEM_WITH_PROJECT:
      return t('Problem with Project');
    case REJ_PROBLEM_WITH_MENTOR:
      return t('Problem with Mentor');
    case REJ_PROBLEM_WITH_LANG:
      return t('Problem with Language');
    case REJ_PROBLEM_WITH_CONNECTIVITY:
      return t('Problem with Connectivity');
    case REJ_BECAME_INACTIVE:
      return t('Became inactive');
    case REJ_OTHER:
      return t('Other (no email sent)');
    default:
      return undefined;
  }
};

// Reject Application Options
const getTooEarlyOption = t => ({
  value: REJ_TOO_EARLY_STAGE,
  label: getRejectedStatusDisplay(t, REJ_TOO_EARLY_STAGE),
});

const getTooMatureOption = t => ({
  value: REJ_TOO_MATURE,
  label: getRejectedStatusDisplay(t, REJ_TOO_MATURE),
});

const getProblemWithProjectOption = t => ({
  value: REJ_PROBLEM_WITH_PROJECT,
  label: getRejectedStatusDisplay(t, REJ_PROBLEM_WITH_PROJECT),
});

const getProblemWithMentorOption = t => ({
  value: REJ_PROBLEM_WITH_MENTOR,
  label: getRejectedStatusDisplay(t, REJ_PROBLEM_WITH_MENTOR),
});

const getProblemWithLanguageOption = t => ({
  value: REJ_PROBLEM_WITH_LANG,
  label: getRejectedStatusDisplay(t, REJ_PROBLEM_WITH_LANG),
});

const getProblemWithConnectivityOption = t => ({
  value: REJ_PROBLEM_WITH_CONNECTIVITY,
  label: getRejectedStatusDisplay(t, REJ_PROBLEM_WITH_CONNECTIVITY),
});

const getBecameInactiveOption = t => ({
  value: REJ_BECAME_INACTIVE,
  label: getRejectedStatusDisplay(t, REJ_BECAME_INACTIVE),
});

const getOtherOption = t => ({
  value: REJ_OTHER,
  label: getRejectedStatusDisplay(t, REJ_OTHER),
});

export const getProjectRejectOptions = t => [
  getTooEarlyOption(t),
  getTooMatureOption(t),
  getProblemWithProjectOption(t),
  getProblemWithMentorOption(t),
  getProblemWithLanguageOption(t),
  getProblemWithConnectivityOption(t),
  getBecameInactiveOption(t),
  getOtherOption(t),
];


// Dropped reasons
const DROP_INACTIVE = 0;
const DROP_TIMING = 1;
const DROP_NO_MENTOR_INTEREST = 2;
const DROP_DIDNT_LIKE_MENTORS = 3;
const DROP_NOT_SUPPORTED_BY_MENTOR = 4;
const DROP_NOT_SUPPORTED_BY_BRIDGE = 5;
const DROP_CONFUSED_FRUSTRATED = 6;
const DROP_NOT_PREPARED = 7;
const DROP_OVER_PREPARED = 8;
const DROP_PAUSED_PROGRAM = 9;


export const getDroppedReasonDisplay = (t, droppedReason) => {
  switch (droppedReason) {

    case DROP_INACTIVE:
      return t('Became Inactive');
    case DROP_TIMING:
      return t('Bad Timing');
    case DROP_NO_MENTOR_INTEREST:
      return t('Had no mentor interest');
    case DROP_DIDNT_LIKE_MENTORS:
      return t('Didnt like mentors');
    case DROP_NOT_SUPPORTED_BY_MENTOR:
      return t('Not supported by mentor');
    case DROP_NOT_SUPPORTED_BY_BRIDGE:
      return t('Not supported by bridge');
    case DROP_CONFUSED_FRUSTRATED:
      return t('Confused / Frustrated');
    case DROP_NOT_PREPARED:
      return t('Was not prepared');
    case DROP_OVER_PREPARED:
      return t('Was over-prepared');
    case DROP_PAUSED_PROGRAM:
      return t('Paused Program');
    default:
      return undefined;
  }
};

const getBecameIncative = t => ({
  value: DROP_INACTIVE,
  text: getDroppedReasonDisplay(t, DROP_INACTIVE),
});

const getBadTiming = t => ({
  value: DROP_TIMING,
  text: getDroppedReasonDisplay(t, DROP_TIMING),
});

const getNoInterest = t => ({
  value: DROP_NO_MENTOR_INTEREST,
  text: getDroppedReasonDisplay(t, DROP_NO_MENTOR_INTEREST),
});

const getDidntLikeMentors = t => ({
  value: DROP_DIDNT_LIKE_MENTORS,
  text: getDroppedReasonDisplay(t, DROP_DIDNT_LIKE_MENTORS),
});

const getNotSupportedByM = t => ({
  value: DROP_NOT_SUPPORTED_BY_MENTOR,
  text: getDroppedReasonDisplay(t, DROP_NOT_SUPPORTED_BY_MENTOR),
});

const getNotSupportedByBridge = t => ({
  value: DROP_NOT_SUPPORTED_BY_BRIDGE,
  text: getDroppedReasonDisplay(t, DROP_NOT_SUPPORTED_BY_BRIDGE),
});

const getConfused = t => ({
  value: DROP_CONFUSED_FRUSTRATED,
  text: getDroppedReasonDisplay(t, DROP_CONFUSED_FRUSTRATED),
});

const getNotPrepared = t => ({
  value: DROP_NOT_PREPARED,
  text: getDroppedReasonDisplay(t, DROP_NOT_PREPARED),
});

const getOverPrepared = t => ({
  value: DROP_OVER_PREPARED,
  text: getDroppedReasonDisplay(t, DROP_OVER_PREPARED),
});

const getPausedProgram = t => ({
  value: DROP_PAUSED_PROGRAM,
  text: getDroppedReasonDisplay(t, DROP_PAUSED_PROGRAM),
});

export const getEntrepreneurDropOptions = t => ({
  INACTIVE: getBecameIncative(t),
  BAD_TIMING: getBadTiming(t),
  NO_INTEREST: getNoInterest(t),
  DIDNT_LIKE_MENTORS: getDidntLikeMentors(t),
  NOT_SUPPORTED_BY_M: getNotSupportedByM(t),
  NOT_SUPPORTED_BY_BRIDGE: getNotSupportedByBridge(t),
  CONFUSED: getConfused(t),
  NOT_PREPARED: getNotPrepared(t),
  OVER_PREPARED: getOverPrepared(t),
  PAUSED_PROGRAM: getPausedProgram(t),
});

// Accept/Move Options
export const getAcceptOptionDisplay = (t, status) => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return t('Move to Application');
    case STATUS_PENDING_DECISION:
      return t('Move to Selection');
    case STATUS_APPROVED_NO_EMAIL:
      return t('Accept, but no email sent');
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return t('Accept & Attend Onboarding Session');
    case STATUS_UNMATCHED:
      return t('Accept & move directly to MATCHING');
    case STATUS_INCUBATING:
      return t('Accept & move directly to INCUBATING');
    default:
      return undefined;
  }
};

const getIncompleteOption = t => ({
  value: STATUS_IN_PROGRESS,
  label: getAcceptOptionDisplay(t, STATUS_IN_PROGRESS),
});

const getSelectionOption = t => ({
  value: STATUS_PENDING_DECISION,
  label: getAcceptOptionDisplay(t, STATUS_PENDING_DECISION),
});

const getFollowUpOption = t => ({
  value: STATUS_APPROVED_NO_EMAIL,
  label: getAcceptOptionDisplay(t, STATUS_APPROVED_NO_EMAIL),
});

const getApprovedOption = t => ({
  value: STATUS_APPROVED_ONBOARDING_SESSION,
  label: getAcceptOptionDisplay(t, STATUS_APPROVED_ONBOARDING_SESSION),
});

const getMatchingOption = t => ({
  value: STATUS_UNMATCHED,
  label: getAcceptOptionDisplay(t, STATUS_UNMATCHED),
});

const getIncubatingOption = t => ({
  value: STATUS_INCUBATING,
  label: getAcceptOptionDisplay(t, STATUS_INCUBATING),
});

export const getProjectAcceptOptions = t => [
  getIncompleteOption(t),
  getSelectionOption(t),
  getApprovedOption(t),
  getFollowUpOption(t),
  getMatchingOption(t),
  getIncubatingOption(t),
];

// Review Statuses
const REVIEW_STATUS_PENDING_REVIEW = 0;
const REVIEW_STATUS_IN_PROGRESS = 1;
const REVIEW_STATUS_REVIEWED = 2;
const REVIEW_STATUS_DONE = 3;
const REVIEW_STATUS_NO_MATCH = 4;
const REVIEW_STATUS_EXPIRED = 5;
const REVIEW_STATUS_RANKING_PENDING = 6;
const REVIEW_STATUS_INCUBATING = 7;
const REVIEW_STATUS_ALUMNI = 8;

export const getApplicationReviewStatusDisplay = (t, reviewStatus) => {
  switch (reviewStatus) {
    case REVIEW_STATUS_PENDING_REVIEW:
      return t('Pending Review');
    case REVIEW_STATUS_IN_PROGRESS:
      return t('In Progress');
    case REVIEW_STATUS_REVIEWED:
      return t('Reviewed');
    case REVIEW_STATUS_DONE:
      return t('Done');
    case REVIEW_STATUS_NO_MATCH:
      return t('No Match');
    case REVIEW_STATUS_EXPIRED:
      return t('Expired');
    case REVIEW_STATUS_RANKING_PENDING:
      return t('Ranking Pending');
    case REVIEW_STATUS_INCUBATING:
      return t('Incubating');
    case REVIEW_STATUS_ALUMNI:
      return t('Alumni');
    default:
      return '';
  }
};

// Surveys
const SURVEY_STATUS_PENDING = 0;
const SURVEY_STATUS_SENT = 1;

const MemberApplicationRecord = Record({
  id: null,
  member: null,
  review_status: null,
  additional_answer: null,
  additional_answer_1: null,
  additional_answer_2: null,
  additional_answer_3: null,
  additional_answer_4: null,
  additional_answer_5: null,
  additional_answer_6: null,
  additional_answer_7: null,
  check_commitment: null,
  check_duration: null,
  check_payment: null,
  complete_surveys: [],
  dropped: null,
  need_grant: null,
  matching_notes: '',
  application_notes: '',
  incubating_notes: '',
  open_to_comentor: null,
  participate_in_another_program_if_rejected: null,
  program_type: null,
  program: null,
  progress: null,
  project: null,
  pti_survey_status: SURVEY_STATUS_PENDING,
  ranking_reasons: null,
  ranking_submitted: null,
  ranking: [],
  recommended_program_referrer: null,
  recommended_program: null,
  referrer: null,
  rejected: null,
  remark: null,
  status: null,
  survey_responses_eoi: [],
  survey_responses_pc_ent: [],
  survey_responses_pp: [],
  survey_responses_pti: [],
  team_description: '',
  team: null,
  venture_summary_complete: null,
  last_follow_up_date: null,
});

class MemberApplication extends MemberApplicationRecord {
  // Statuses
  static STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  static STATUS_PENDING_DECISION = STATUS_PENDING_DECISION;
  static STATUS_APPROVED_NO_EMAIL = STATUS_APPROVED_NO_EMAIL;
  static STATUS_APPROVED_ONBOARDING_SESSION = STATUS_APPROVED_ONBOARDING_SESSION;
  static STATUS_UNMATCHED = STATUS_UNMATCHED;
  static STATUS_MATCHED = STATUS_MATCHED;
  static STATUS_INCUBATING = STATUS_INCUBATING;
  static STATUS_ALUMNI = STATUS_ALUMNI;

  // Rejected reasons
  static REJ_TOO_EARLY_STAGE = REJ_TOO_EARLY_STAGE;
  static REJ_TOO_MATURE = REJ_TOO_MATURE;
  static REJ_PROBLEM_WITH_PROJECT = REJ_PROBLEM_WITH_PROJECT;
  static REJ_PROBLEM_WITH_MENTOR = REJ_PROBLEM_WITH_MENTOR;
  static REJ_PROBLEM_WITH_LANG = REJ_PROBLEM_WITH_LANG;
  static REJ_PROBLEM_WITH_CONNECTIVITY = REJ_PROBLEM_WITH_CONNECTIVITY;
  static REJ_BECAME_INACTIVE = REJ_BECAME_INACTIVE;
  static REJ_OTHER = REJ_OTHER;

  // Dropped reasons
  static DROP_INACTIVE = DROP_INACTIVE;
  static DROP_TIMING = DROP_TIMING;
  static DROP_NO_MENTOR_INTEREST = DROP_NO_MENTOR_INTEREST;
  static DROP_DIDNT_LIKE_MENTORS = DROP_DIDNT_LIKE_MENTORS;
  static DROP_NOT_SUPPORTED_BY_MENTOR = DROP_NOT_SUPPORTED_BY_MENTOR;
  static DROP_NOT_SUPPORTED_BY_BRIDGE = DROP_NOT_SUPPORTED_BY_BRIDGE;
  static DROP_CONFUSED_FRUSTRATED = DROP_CONFUSED_FRUSTRATED;
  static DROP_NOT_PREPARED = DROP_NOT_PREPARED;
  static DROP_OVER_PREPARED = DROP_OVER_PREPARED;
  static DROP_PAUSED_PROGRAM = DROP_PAUSED_PROGRAM;

  // Review statuses
  static REVIEW_STATUS_PENDING_REVIEW = REVIEW_STATUS_PENDING_REVIEW;
  static REVIEW_STATUS_IN_PROGRESS = REVIEW_STATUS_IN_PROGRESS;
  static REVIEW_STATUS_REVIEWED = REVIEW_STATUS_REVIEWED;
  static REVIEW_STATUS_DONE = REVIEW_STATUS_DONE;
  static REVIEW_STATUS_NO_MATCH = REVIEW_STATUS_NO_MATCH;
  static REVIEW_STATUS_EXPIRED = REVIEW_STATUS_EXPIRED;
  static REVIEW_STATUS_RANKING_PENDING = REVIEW_STATUS_RANKING_PENDING;
  static REVIEW_STATUS_INCUBATING = REVIEW_STATUS_INCUBATING;
  static REVIEW_STATUS_ALUMNI = REVIEW_STATUS_ALUMNI;

  // Survey statuses
  static SURVEY_STATUS_PENDING = SURVEY_STATUS_PENDING;
  static SURVEY_STATUS_SENT = SURVEY_STATUS_SENT;

  get id() {
    return this.get('id');
  }

  getStatusDisplay = t => getStatusDisplay(t, this.get('status')); // Not used
  getRejectedStatusDisplay = t => getRejectedStatusDisplay(t, this.get('rejected')); // Not used
  getDroppedReasonDisplay = t => getDroppedReasonDisplay(t, this.get('dropped')); // Not used
}

export default MemberApplication;
