import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import bfbTheme from 'styles/b4b-theme';

// Assets
import { useUploadProjectFinancialForecastScenarioAndMentorNotifyMutation } from 'services/apiSlices/projects/projectsApi';

import FinancialForecast, { SPREADSHEET_GOOGLE } from 'models/FinancialForecast';
import Project from 'models/Project';
import Step from 'models/Tools/Step';
import TranslationsContext from 'providers/translationsProvider';
import { ReactComponent as excelIcon } from 'static/icons/Microsoft_Excel-Logo.wine.svg';
import { SECURED_ENOUGH_FUDING } from 'styles/toolColors';

// Components
import Icon, { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Upload } from 'antd';
import { ToolDefaultInput, ToolInput } from 'components/Tools';
import { Button, Checkbox, Form, Modal } from 'components/elements';
import { H1 } from 'components/fontSystem';
import { Row } from 'components/layout';

import { actions as toolActions, selectors as toolSelectors } from 'store/modules/Tools/tool';

const SPREADSHEET_TYPE = 'spreadsheet_type';
const SPREADSHEET_FILE = 'spreadsheet_file';
const SPREADSHEET_URL = 'spreadsheet_url';
const UPLOAD_NOTES = 'notes';
const PROJECT_STEPS = 'modified_steps';

const UploadModal = ({
  project,
  steps = [],
  visible,
  handleModal,
  financialForecast,
  refetchProjectFinancialForecast,
  handleFinancialForecastScenarioUpdate,
  handleStepsListFetch,
}) => {
  const { format, t } = useContext(TranslationsContext);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const tools = useSelector(toolSelectors.getTools);

  useEffect(() => {
    // Fetching tools if not already loaded, they are fetched from the ToolsDropdown on
    // src/layout/ToolHeader/components/ToolsDropdownMenu/index.js which is not rendered in mobile

    // TODO: by fixing this we are producing a duplicated request on desktop, not sure why
    if (tools.length === 0 && project.program && project.id) {
      dispatch(toolActions.listTools({ program: project.program, project: project.id }));
    }
  }, [project, tools.length]);

  const [uploadFinancialProjectionScenarioAndMentorNotify] =
    useUploadProjectFinancialForecastScenarioAndMentorNotifyMutation();

  if (!financialForecast) return;

  const defaultScenario = financialForecast.scenarios.find(scenario => scenario.default === true);

  const [formState, setFormState] = useState({
    [SPREADSHEET_URL]: null,
    [SPREADSHEET_FILE]: null,
    [UPLOAD_NOTES]: null,
    [PROJECT_STEPS]: null,
  });

  useEffect(() => {
    const completedSteps = steps
      .filter(step => step.status === Step.STATUS_COMPLETE)
      .map(step => step.id);
    form.setFieldsValue({ [PROJECT_STEPS]: completedSteps });
  }, [steps, form]);

  const handleOnChangeForm = changedFields => {
    const changedField = changedFields[0];
    setFormState({
      ...formState,
      [changedField.name]: changedField.value,
    });
  };

  const handleOnSubmitForm = () => {
    form
      .validateFields()
      .then(_values => {
        const formData = new FormData();
        if (formState[SPREADSHEET_FILE] && formState[SPREADSHEET_FILE].file) {
          formData.append(SPREADSHEET_FILE, formState[SPREADSHEET_FILE].file);
        }

        if (formState[SPREADSHEET_URL]) {
          formData.append(SPREADSHEET_URL, formState[SPREADSHEET_URL]);
        }

        if (formState[PROJECT_STEPS]) {
          formState[PROJECT_STEPS].forEach(item => {
            formData.append(PROJECT_STEPS, item);
          });
        }

        if (formState[UPLOAD_NOTES]) {
          formData.append(UPLOAD_NOTES, formState[UPLOAD_NOTES]);
        }

        if (defaultScenario[SPREADSHEET_FILE]) {
          handleFinancialForecastScenarioUpdate({
            scenario: defaultScenario,
            name: SPREADSHEET_FILE,
            value: null,
          });
          setTimeout(() => {}, 500);
        }

        // Only fire the request if the formData has values
        if (!formData.entries().next().done) {
          uploadFinancialProjectionScenarioAndMentorNotify({
            projectId: project.id,
            data: formData,
          })
            .unwrap()
            .then(() => {
              refetchProjectFinancialForecast();
              handleStepsListFetch();
              handleModal();
            })
            .catch(error => console.error(error));
        }
      })
      .catch(_errorInfo => {});
  };

  return (
    <Modal
      open={visible}
      size="large"
      onCancel={handleModal}
      xColor={bfbTheme.memberGreen}
      footer={[
        <Button icon={<CheckOutlined />} key="submit" type="primary" onClick={handleOnSubmitForm}>
          {t('UPLOAD & SAVE')}
        </Button>,
      ]}
      centered
      width={500}
    >
      <Row>
        <Col span={24}>
          <H1 color={bfbTheme.memberGreen}>{t('Upload your latest work')}</H1>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFieldsChange={handleOnChangeForm}>
        {financialForecast[SPREADSHEET_TYPE] === SPREADSHEET_GOOGLE ? (
          <FormItem
            label={format(t('1. Add the Google Sheet URL of your first scenario - %(name)s'), {
              name: defaultScenario.scenario_name,
            })}
            extra={
              <p>
                {t(
                  'Make sure to share your google sheet with your mentor so they have access to it.'
                )}
              </p>
            }
            rules={[{ required: true, message: t('Please add your google sheet URL here') }]}
            name={SPREADSHEET_URL}
          >
            <ToolDefaultInput
              name={SPREADSHEET_URL}
              bgColor="transparent"
              colorIndex={SECURED_ENOUGH_FUDING}
              empty
              form={form}
              max={150}
              placeholder={t('Add your google sheet URL here')}
              showSelect={false}
              showComments={false}
              showDelete={false}
            />
          </FormItem>
        ) : (
          <FormItem
            name={SPREADSHEET_FILE}
            label={format(t('1. Upload the Excel sheet of your first scenario - %(name)s'), {
              name: defaultScenario.scenario_name,
            })}
            extra={
              <p>{t('Whenever you upload, the newer version will override the older one.')}</p>
            }
            rules={[{ required: true, message: t('Please upload your file here') }]}
          >
            <StyledUpload
              accept=".csv, .xlsx"
              multiple={false}
              name={SPREADSHEET_FILE}
              showUploadList={false}
              beforeUpload={() => false}
            >
              <Button type="primary" icon={<UploadOutlined />} size="small">
                {t('Upload')}
              </Button>
            </StyledUpload>
          </FormItem>
        )}
        {formState[SPREADSHEET_FILE] && (
          <GreyWrapper>
            <Icon style={{ fontSize: '28px' }} component={excelIcon} />
            <span style={{ color: bfbTheme.hyperlink }}>
              {formState[SPREADSHEET_FILE].file.name}
            </span>
          </GreyWrapper>
        )}
        <FormItem
          name={PROJECT_STEPS}
          label={t('2. Indicate which steps are complete.')}
          extra={
            <p>
              {t(
                'It’s important to keep track of the step completion so your mentor is aware of which step is ready for review, and your program progress will be more accurate.'
              )}
            </p>
          }
          size="large"
          rules={[{ required: true, message: t('This section is required') }]}
        >
          <Checkbox.Group>
            {steps.map(step => (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <StyledCheckbox value={step.id}>{step.name}</StyledCheckbox>
              </div>
            ))}
          </Checkbox.Group>
        </FormItem>
        <FormItem
          label={t('3. Leave a note for your mentor about this version.')}
          extra={
            <p>
              {t(
                'If there are particular things you want your mentor to pay attention to, leave a note here.'
              )}
            </p>
          }
          size="large"
          name={UPLOAD_NOTES}
        >
          <ToolInput.TextArea
            name={UPLOAD_NOTES}
            placeholder={t('Write your notes here')}
            rows={5}
            size="large"
            style={{ maxWidth: '650px' }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

const FormItem = styled(Form.Item)`
  &&& {
    div .ant-form-item-label {
      padding-bottom: 0;
    }

    .ant-form-item-label label .label-content {
      margin-bottom: 0;
    }

    .ant-form-item-extra {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
        color: black;
        font-weight: 400;
      }
    }

    .ant-form-item-control-input-content > div {
      margin-top: 10px;
    }
  }
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    height: 35px !important;
  }
  .ant-upload-list-item-thumbnail {
    height: 30px !important;
  }
  &.ant-upload-list.ant-upload-list-text {
    width: fit-content;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 4px 0;
`;

const GreyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  width: 100%;
  color: black;
  background-color: #f2f2f2;
  font-size: 15px;
  padding: 5px 8px;
  margin: 7px 0;
  border-radius: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

UploadModal.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  steps: PropTypes.instanceOf(Array),
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  financialForecast: PropTypes.instanceOf(FinancialForecast).isRequired,
  refetchProjectFinancialForecast: PropTypes.func.isRequired,
  handleFinancialForecastScenarioUpdate: PropTypes.func.isRequired,
  handleStepsListFetch: PropTypes.func.isRequired,
};

export default UploadModal;
