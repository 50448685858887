import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const programsApi = createApi({
  reducerPath: 'programsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/programs/',
  }),
  endpoints: builder => ({
    // Fetch ALL programs
    getPrograms: builder.query({
      query: ({ organization, programStatus, search, limit, offset }) => ({
        params: {
          ...(organization && { organization }),
          ...(programStatus && { program_status: programStatus }),
          ...(search && { search }),
          ...(limit && { limit }),
          ...(offset && { offset }),
        },
      }),
    }),
    // Fetch program by program's id
    getProgramById: builder.query({
      query: programId => ({ url: `${programId}/` }),
    }),
    // Add admin to the program
    addProgramAdmin: builder.mutation({
      query: ({ programId, adminId }) => ({
        url: `${programId}/admins/`,
        method: 'POST',
        data: { admin: adminId },
      }),
    }),
    // Remove admin from the program
    removeProgramAdmin: builder.mutation({
      query: ({ programId, adminId }) => ({
        url: `${programId}/admins/${adminId}/`,
        method: 'DELETE',
      }),
    }),
    // List program's admins - IMs and PMs ADMINS
    getProgramAdmins: builder.query({
      query: programId => ({ url: `${programId}/admins/` }),
    }),
    // Create a new IM and link them to the program
    createProgramAdmin: builder.mutation({
      query: ({ programId, adminData }) => ({
        url: `${programId}/admins/account/`,
        method: 'POST',
        data: {
          first_name: adminData.first_name,
          last_name: adminData.last_name,
          email: adminData.email,
        },
      }),
    }),
    // Show a default pill in this program
    showPillInProgram: builder.mutation({
      query: ({ pillId, programId }) => ({
        method: 'POST',
        url: `${programId}/pills/`,
        data: { pill: pillId },
      }),
    }),
    // Hide a default pill in this program
    hidePillInProgram: builder.mutation({
      query: ({ pillId, programId }) => ({
        method: 'DELETE',
        url: `${programId}/pills/${pillId}/`,
      }),
    }),
  }),
});

export const {
  useGetProgramsQuery,
  useLazyGetProgramsQuery,
  useGetProgramByIdQuery,
  useAddProgramAdminMutation,
  useRemoveProgramAdminMutation,
  useGetProgramAdminsQuery,
  useCreateProgramAdminMutation,
  useHidePillInProgramMutation,
  useShowPillInProgramMutation,
} = programsApi;
