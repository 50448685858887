import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// Fetch a token for demo projects used in an iframe.
export const demoTokenAPI = createApi({
  reducerPath: 'demoTokenAPI',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/demo-token/',
  }),
  endpoints: builder => ({
    getDemoToken: builder.query({
      query: ({ exampleId, language }) => ({
        method: 'GET',
        params: { example_id: exampleId, lang: language },
      }),
    }),
  }),
});

export const { useGetDemoTokenQuery } = demoTokenAPI;
