import { Flex, Spin } from 'antd';

const LoadingPlaceholder = ({ height = 50, width = 160 }) => (
  <Flex align="center" justify="center">
    <Spin size="small">
      <div
        style={{
          padding: `${height}px ${width}px`,
          background: 'rgba(255, 255, 255, 0.78)',
          borderRadius: 4,
        }}
      />
    </Spin>
  </Flex>
);

export default LoadingPlaceholder;
