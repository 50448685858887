import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const adminsApi = createApi({
  reducerPath: 'adminsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/admins/',
  }),
  endpoints: builder => ({
    // List all IMs and PMs (aka Admins)
    getAdmins: builder.query({
      query: data => ({
        params: data,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAdminsQuery } = adminsApi;
