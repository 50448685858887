/* eslint-disable camelcase */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { ProgressCircle } from 'components/elements';
import { P } from 'components/fontSystem';
import { Popover } from 'antd';
import LoadingPlaceHolder from 'components/elements/LoadingPlaceHolder';

const GoalStats = props => {
  const { data, t } = props;

  if (!data || !data.program_goals) return <LoadingPlaceHolder />;

  const LILAC = '#5C5CEA';
  const BLUE = '#0FC5D8';
  const GREEN = '#1A754E';

  return (
    <Wrapper>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Application Goal Completion ')}</b>(<span style={{ color: BLUE }}>%</span>){' '}
              {t('= Application Submitted ')}(
              <span style={{ color: BLUE }}>{data.program_goals.application_count}</span>)
              {t(' / Application Goal ')}(
              <span style={{ color: BLUE }}>{data.program_goals.application_goal}</span>) * 100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={BLUE}
          color={BLUE}
          opacity="rgba(15, 196, 216, 0.1)"
          percent={data.program_goals.application_avg}
          strokeWidth={10}
          type="circle"
          size={85}
        />
      </Popover>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Approval Goal Completion ')}</b>(<span style={{ color: GREEN }}>%</span>){' '}
              {t('= Application Approved ')}(
              <span style={{ color: GREEN }}>{data.program_goals.approval_count}</span>)
              {t(' / Approval Goal ')}(
              <span style={{ color: GREEN }}>{data.program_goals.approval_goal}</span>) * 100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={GREEN}
          color={GREEN}
          opacity="rgba(26, 117, 78, 0.1)"
          percent={data.program_goals.approval_avg}
          strokeWidth={10}
          type="circle"
          size={85}
        />
      </Popover>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Incubation Goal Completion ')}</b>(<span style={{ color: LILAC }}>%</span>){' '}
              {t('= Projects in Incubation ')}(
              <span style={{ color: LILAC }}>{data.program_goals.incubation_count}</span>)
              {t(' / Incubation Goal ')}(
              <span style={{ color: LILAC }}>{data.program_goals.incubation_goal}</span>) * 100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={LILAC}
          color={LILAC}
          opacity="rgba(92, 92, 234, 0.1)"
          percent={data.program_goals.incubation_avg}
          strokeWidth={10}
          size={85}
          type="circle"
        />
      </Popover>
      <div className="text-wrapper">
        <div className="text">
          <P>{t('Application Goal')}</P>
          <span data-test-id="app-goal">{data.program_goals.application_goal}</span>
        </div>
        <div className="text">
          <P>{t('Approval Goal')}</P>
          <span className="green" data-test-id="approval-goal">
            {data.program_goals.approval_goal}
          </span>
        </div>
        <div className="text">
          <P>{t('Incubation Goal')}</P>
          <span className="lilac" data-test-id="incubation-goal">
            {data.program_goals.incubation_goal}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

GoalStats.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(18, 61, 198, 0.2);
    .ant-progress {
      padding: 0 8px;
    }
    .text-wrapper {
      min-width: 130px;
      color: #0fc5d8;
      .text {
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
        }
        .lilac {
          color: '#5C5CEA';
        }
        .green {
          color: '#1A754E';
        }
      }
    }
  }
`;

export default GoalStats;
