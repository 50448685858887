import { languages } from 'utils/translationHandlers';
import bfbTheme from './b4b-theme';

const getFont = language => {
  switch (language) {
    case languages.LO:
      return 'Phetsarath, "Source Sans 3", sans-serif';
    case languages.KM:
      return 'Hanuman, "Source Sans 3", sans-serif';
    default:
      return '"Source Sans 3", "Roboto", sans-serif';
  }
};

const getAntTheme = language => ({
  token: {
    // Seed token (general)
    borderRadius: 4,
    colorError: bfbTheme.error,
    colorInfo: bfbTheme.hyperlink, // FIXME: Ask Jess if we should change this
    colorLink: bfbTheme.hyperlink,
    colorPrimary: bfbTheme.mainColor,
    colorSuccess: bfbTheme.teal,
    colorWarning: bfbTheme.warning,
    controlHeight: 35,
    fontSize: 15,
    fontFamily: getFont(language),

    // Map token (modifyers)
    colorPrimaryHover: bfbTheme.mainColor, // input border color on hover
    colorTextTertiary: bfbTheme.grey,
    lineHeight: 1.666667,
    fontSizeHeading1: 24,
    fontSizeHeading2: 22,
    fontSizeHeading3: 20,
    fontSizeHeading4: 20,

    // Alias (specific elements)
    colorIconHover: bfbTheme.teal, // icons inside inputs
    colorTextPlaceholder: bfbTheme.grey,
    controlOutline: 'transparent', // focused inputs
    colorBgSpotlight: 'white', // This is currently only used in the background color of Tooltip.
  },
  // Table Global Styles
  components: {
    Form: {
      itemMarginBottom: 6,
    },
    Menu: {
      itemActiveBg: bfbTheme.tealFade,
      itemSelectedBg: bfbTheme.tealFade,
      itemHoverBg: bfbTheme.tealFade,
    },
    Table: {
      cellFontSize: 15,
      rowHoverBg: '#EBFEF7',
      rowSelectedBg: '#EBFEF7',
      rowSelectedHoverBg: '#EBFEF7',
    },
    Slider: {
      dotSize: 14,
      railSize: 8,
      handleSize: 18,
      handleSizeHover: 18,
      handleLineWidth: 3,
      handleLineWidthHover: 3,
    },
  },
});

export { getAntTheme };
