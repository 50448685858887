import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

const PILLS_TAG = 'Pills';

// API module for listing pills.
export const pillsApi = createApi({
  reducerPath: 'pillsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/pills/',
  }),
  tagTypes: [PILLS_TAG],
  endpoints: builder => ({
    // List all pills
    listPills: builder.query({
      query: query => ({ params: query }),
      providesTags: [PILLS_TAG],
    }),
    // Create a pill
    createPill: builder.mutation({
      query: data => ({
        method: 'POST',
        data,
      }),
      invalidatesTags: [PILLS_TAG],
    }),
    // Update a pill
    updatePill: builder.mutation({
      query: ({ pillId, formData: data }) => ({
        method: 'PUT',
        url: `${pillId}/`,
        data,
      }),
      invalidatesTags: [PILLS_TAG],
    }),
    // Delete a pill
    deletePill: builder.mutation({
      query: pillId => ({
        url: `${pillId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [PILLS_TAG],
    }),
  }),
});

export const {
  useCreatePillMutation,
  useDeletePillMutation,
  useListPillsQuery,
  useUpdatePillMutation,
} = pillsApi;
