// Libraries
import { call, put, all, takeLatest } from 'redux-saga/effects';

// Dependencies
import { actions as customizationActions } from 'store/modules/customization';
import { actions as memberActions } from 'store/modules/member';
import { actions as mentorActions } from 'store/modules/mentor';
import { actions as translationActions } from 'store/modules/translations';
import serviceAuth, { ACCESS_TOKEN, TROUBLESHOOT_TOKEN } from 'services/auth';
import serviceUser from 'services/user/user';
import normalizeUser from 'store/normalizers/userNormalizer';
import { loginMixpanel, signupMixpanel } from 'utils/mixpanel';
import { setAuth } from 'services/api';

import {
  customization as customizationKey,
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/localStorage.js';

// Local Dependencies
import userActions from './actions';
import types from './types';
import queryString from 'utils/queryString';

export function* fetchUser(action = { payload: {} }) {
  const { callback } = action.payload;
  try {
    const response = yield call(serviceUser.getAccount);
    const { user, customization, member, mentor } = yield call(normalizeUser, response.data);
    const isAdmin = mentor && mentor.is_admin;
    if (callback) callback({ user, member, mentor });
    yield put(
      userActions.userFetchSucceeded({
        userData: { is_admin: isAdmin, ...user },
      })
    );
    yield member && put(memberActions.memberFetchSucceeded({ memberData: member }));
    yield mentor && put(mentorActions.mentorFetchSucceeded({ mentorData: mentor }));
    // if user has customization
    if (customization) {
      // apply customization
      yield put(customizationActions.fetchCustomizationSucceeded(customization));
      // then check in local storage if customization key exists so we can customize auth pages
      const customizationValue = getLocalStorageItem(customizationKey);
      // if it doesn't exist in localStorage or is different than the program
      if (!customizationValue || customizationValue !== customization.program) {
        // set new local storage key with program id
        setLocalStorageItem(customizationKey, customization.program);
      }
    }
    if (member || mentor) {
      const selectedLanguage = (member || mentor).language;
      yield put(translationActions.translationsChange({ selectedLanguage }));
    }
    loginMixpanel(member, mentor, isAdmin);
  } catch (e) {
    yield put(userActions.userFetchFailed({ message: e.message }));
  }
}

export function* userLogin(action) {
  const { username, password, callback } = action.payload;
  try {
    const auth = yield call(serviceAuth.getAuth, { username, password });
    if (!auth) throw new Error('Could not authenticate');
    serviceAuth.setToken({
      accessToken: auth.data.access_token,
    });
    yield put(userActions.userFetchRequest({ callback }));
    yield call(serviceUser.getAccount);
  } catch (e) {
    const error = e.response ? e.response.data.error_description || e.response.data : String(e);
    yield put(
      userActions.userFetchFailed({
        message: error,
      })
    );
  }
}

export function* userRegister(action) {
  const { username, password, re_password, member, mentor, organization, program, callback } =
    action.payload;
  try {
    const user = yield call(serviceAuth.registerAuth, {
      username,
      password,
      re_password,
      member,
      mentor,
      organization,
      program,
    });
    yield put(userActions.userRegisterSucceeded({ userData: user.data }));
    yield put(
      userActions.userLogin({
        username: user.data.username,
        password,
        callback,
      })
    );
    // Mixpanel event
    signupMixpanel(user.data);
  } catch (e) {
    yield put(userActions.userRegisterFailed({ message: e.response.data }));
  }
}

// Check if the troubleshoot token is present in the URL and if we're in the troubleshoot mode on a iframe or not
const queryParams = new URLSearchParams(window.location.search);
const troubleshoot = queryParams.get('troubleshoot');

// Get the token from the local storage or the session storage according to the troubleshoot mode
const usedToken = troubleshoot
  ? sessionStorage.getItem(TROUBLESHOOT_TOKEN)
  : localStorage.getItem(ACCESS_TOKEN);

export function* userRestore() {
  const params = queryString.parse(document.location.search);
  const accessToken = usedToken || params.access_token;

  if (accessToken) {
    setAuth(accessToken);
    yield fetchUser();
    yield put(userActions.userIsAccessible());
  }
  yield put(userActions.userIsAccessible());
}

export function* forgotPassword(action) {
  const { email } = action.payload;
  try {
    yield call(serviceAuth.forgotPassword, email);
    yield put(userActions.forgotPasswordSucceeded());
  } catch (e) {
    yield put(
      userActions.forgotPasswordFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* resetPassword(action) {
  try {
    const { user, password } = action.payload;
    yield call(serviceAuth.resetPassword, action.payload);
    yield put(userActions.userLogin({ username: user, password }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      userActions.userEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* setPassword(action) {
  try {
    const { user, re_new_password, new_password, current_password } = action.payload;
    yield call(serviceAuth.setNewPassword, {
      user,
      re_new_password,
      new_password,
      current_password,
    });
    yield put(userActions.userLogin({ username: user, password: new_password }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      userActions.userEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* userEdit(action) {
  const { uid, token, user, password } = action.payload;
  try {
    yield call(serviceAuth.resetPassword, {
      uid,
      token,
      user,
      password,
    });
    yield put(userActions.userLogin({ username: user, password }));
  } catch (e) {
    yield put(
      userActions.forgotPasswordFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.USER_LOGIN, userLogin),
    takeLatest(types.USER_RESTORE, userRestore),
    takeLatest(types.USER_REGISTER, userRegister),
    takeLatest(types.USER_FORGOT_PASSWORD, forgotPassword),
    takeLatest(types.USER_RESET_PASSWORD, resetPassword),
    takeLatest(types.USER_SET_PASSWORD, setPassword),
    takeLatest(types.USER_EDIT, userEdit),
    takeLatest(types.USER_FETCH_REQUESTED, fetchUser),
  ]);
}

export default authSaga;
