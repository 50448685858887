// Libraries
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styled from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import isFloat from 'utils/isFloat';
import isInteger from 'utils/isInteger';

// Components
import { InputNumber } from 'antd';
import { ToolInputContainer } from 'components/Tools';
import Form from 'components/elements/Form';
import { getInputBackgroundColor } from '../ToolInput/common';

const getIntegerErrorMessage = t => t('Oops! You can only enter numbers');
const getFloatErrorMessage = t => t('Oops! You can only enter numbers or decimal separator');

const [thousandSeparator, decimalSeparator] = Number(12345.6).toLocaleString().match(/(\D+)/g);

/*
  WARNING: If you plan to use this component's value in form.getFieldsValue, it will return a
  String-formatted number. You can use parseNumericInputValue to get the numeric value
*/

export const parseNumericInputValue = number => {
  if (number === undefined || number === null || number === '') return undefined;
  let unformatted = String(number);
  unformatted = unformatted.split(thousandSeparator).join('');
  unformatted = unformatted.split(decimalSeparator).join('.');

  if (!isFloat(unformatted)) return undefined;
  return parseFloat(unformatted);
};

const formatNumber = value => {
  if (value === undefined || value === null || value === '') return undefined;
  return Number(value).toLocaleString();
};

const ToolNumericInput = ({
  disabled = false,
  height = undefined,
  initialValue = null,
  min = undefined,
  name,
  onBlur = null,
  onChange = null,
  placeholder = '',
  rules = [],
  shouldCheckInteger = false,
  shouldNotDecorate = false,
  textAreaMinHeight = undefined,
  ...rest
}) => {

  const { t } = useContext(TranslationsContext);

  const createInputEvent = event => ({
    ...event,
    target: {
      ...event.target,
      value: parseNumericInputValue(event.target.value),
      name,
    },
  });

  const handleOnBlur = event => onBlur && onBlur(createInputEvent(event));
  const handleOnChange = value =>
    onChange &&
    onChange({
      target: {
        value,
        name,
      },
    });

  return (
    <ToolInputContainer height={height} textAreaMinHeight={textAreaMinHeight} {...rest}>
      <StyledFormItem
        initialValue={initialValue}
        name={name}
        rules={[
          ...rules,
          {
            validator: (rule, value) => {
              // check that the value is a number
              if (typeof value !== 'number' && !Number.isFinite(value)) {
                return Promise.reject(new Error(getIntegerErrorMessage(t)));
              }
              // check that the number is an integer
              if (shouldCheckInteger && !isInteger(value)) {
                return Promise.reject(new Error(getIntegerErrorMessage(t)));
              }
              // check that the number is a float
              if (!isFloat(value)) {
                return Promise.reject(new Error(getFloatErrorMessage(t)));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <StyledInputNumber
          bordered={false}
          controls={false}
          disabled={disabled}
          formatter={formatNumber}
          min={min}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onEnter={handleOnBlur}
          parser={parseNumericInputValue}
          placeholder={placeholder}
        />
      </StyledFormItem>
    </ToolInputContainer>
  );
};

ToolNumericInput.propTypes = {
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValue: PropTypes.node,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rules: PropTypes.instanceOf(Array),
  shouldCheckInteger: PropTypes.bool,
  shouldNotDecorate: PropTypes.bool,
  textAreaMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const StyledFormItem = styled(Form.Item)`
  &&& {
    margin-bottom: 0;
    .ant-input {
      transition: all ease 300ms;
      box-shadow: 0px 1px transparent;
    }
    .uncontrolled-input-error {
      color: #f5222d;
      box-shadow: 0px 1px #f5222d;
      border-radius: 0;
    }
    &.ant-form-item-with-help {
      .ant-form-item-children {
        div {
          border-color: #f5222d;
        }
      }
    }
    .ant-form-explain {
      color: red;
    }
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;

  & input {
    &:focus,
    &:hover {
      background: ${({ theme }) => getInputBackgroundColor(theme)};
    }
  }
`;

export default ToolNumericInput;
