import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import {
  ABOUT_YOU,
  AREAS_OF_EXPERTISE,
  EDUCATION,
  FINAL_CHECKLIST,
  MOTIVATION,
  WORK_EXPERIENCE,
} from 'containers/MentorApplication/constants';

const INITIAL_SECTIONS = {
  [ABOUT_YOU]: { requiredFields: 10, errors: {} },
  [AREAS_OF_EXPERTISE]: { requiredFields: 2, errors: {} },
  [WORK_EXPERIENCE]: { requiredFields: 1, errors: {} },
  [EDUCATION]: { requiredFields: 1, errors: {} },
  [MOTIVATION]: { requiredFields: 6, errors: {} },
  [FINAL_CHECKLIST]: { requiredFields: 3, errors: {} },
};
const ALL_SECTIONS = Object.keys(INITIAL_SECTIONS);
const initialState = { ...INITIAL_SECTIONS };

export const validateAllSections = createAsyncThunk(
  'applicationMentorSections/validateAllSections',
  async (form, { rejectWithValue }) => {
    try {
      const validationResponse = await form.validateFields(ALL_SECTIONS, {
        validateOnly: true,
        recursive: true,
      });
      return { sectionNames: ALL_SECTIONS, validationResponse };
    } catch (errorInfo) {
      return rejectWithValue({ sectionNames: ALL_SECTIONS, errorInfo });
    }
  }
);

export const validateSection = createAsyncThunk(
  'applicationMentorSections/validateSection',
  async ({ form, sectionName }, { rejectWithValue }) => {
    try {
      const validationResponse = await form.validateFields([sectionName], {
        validateOnly: true,
        recursive: true,
      });
      return { sectionName, validationResponse };
    } catch (errorInfo) {
      return rejectWithValue({ sectionName, errorInfo });
    }
  }
);

const applicationMentorSectionsSlice = createSlice({
  name: 'applicationMentorSections',
  initialState,
  reducers: {
    registerErrors(state, action) {
      const stateClone = { ...state };
      const { response, form, dataSent } = action.payload;

      // Only handle errors from Bad Request responses (400), capture other errors on Sentry
      if (response.status !== 400) {
        captureException('Error updating the mentor application', {
          extra: JSON.stringify(response, dataSent),
        });
        return;
      }

      const errorInfo = Object.entries(response.data);
      const formErrors = [];
      errorInfo.forEach(sectionErrors => {
        const [section, errors] = sectionErrors;

        // Skip if the error in the response is not a field
        if (!ALL_SECTIONS.includes(section)) {
          captureException('Unexpected error while updating mentor application', {
            extra: JSON.stringify({ section, state: stateClone, dataSent, errorInfo }),
          });
          return;
        }

        Object.entries(errors).forEach(error => {
          const [field, messages] = error;
          if (section === WORK_EXPERIENCE) {
            [stateClone[section].errors[field]] = messages;
            formErrors.push({ name: [section, field, 0], errors: messages[0] });
          } else {
            stateClone[section].errors[field] = messages;
            formErrors.push({ name: [section, field], errors: messages });
          }
        });
      });

      // Show detected errors in the form
      if (formErrors[0]) {
        form.setFields(formErrors);
        form.scrollToField(formErrors[0].name, { block: 'center', behavior: 'smooth' });
      }
      state = stateClone;
    },
  },
  extraReducers: builder => {
    builder
      // Validate Section (no errors)
      .addCase(validateSection.fulfilled, (state, action) => {
        const { sectionName } = action.payload;
        // Clean errors on current section
        const clonedSections = { ...state };
        clonedSections[sectionName].errors = {};
        state = clonedSections;
      })
      // Validate Section (errors)
      .addCase(validateSection.rejected, (state, action) => {
        const { sectionName, errorInfo } = action.payload;
        const clonedSections = { ...state };
        // Reset errors on current section
        clonedSections[sectionName].errors = {};
        // And then re-add errors to the section state
        errorInfo.errorFields.forEach(error => {
          const [section, field] = error.name;
          clonedSections[section].errors[field] = error.errors;
        });
        state = clonedSections;
      })
      // Validate ALL Sections (no errors)
      .addCase(validateAllSections.fulfilled, (state, action) => {
        // Reset to the initial state
        const { sectionNames } = action.payload;
        const clonedSections = { ...state };
        sectionNames.forEach(section => {
          clonedSections[section].errors = {};
        });
        state = clonedSections;
      })
      // Validate ALL Sections (errors)
      .addCase(validateAllSections.rejected, (state, action) => {
        const { sectionNames, errorInfo } = action.payload;
        // Reset to the initial state
        const clonedSections = { ...state };
        sectionNames.forEach(section => {
          clonedSections[section].errors = {};
        });
        // And then re-add errors to every section state
        errorInfo.errorFields.forEach(error => {
          const [section, field] = error.name;
          clonedSections[section].errors[field] = error.errors;
        });
        state = { ...clonedSections };
      });
  },
});

const applicationMentorSectionsReducer = applicationMentorSectionsSlice.reducer;
export const { registerErrors } = applicationMentorSectionsSlice.actions;
export default applicationMentorSectionsReducer;
