// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { PeriodInfo } from 'components/elements';
import { Row } from 'components/layout';
import { Col } from 'antd';
import ApplicationStats from './ApplicationStats';
import ApprovedStats from './ApprovedStats';
import GoalStats from './GoalStats';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import { selectors as programMemberStatsSelectors } from 'store/modules/programStats';

/**
 * A component that renders the statistics for the program management
 * panel in following sections: Application, Selection, Approved.
 * It contains the following statistics: Profile statistics,
 * Application statistics, Approved statistics, Goal statistics.
 *
 * @component
 *
 * @param {string} appStart - Represents the start date or time of an application.
 * @param {string} appEnd - Represents the end date or time of an application.
 * @param {object} statsToShow - Indicates the specific statistics to be displayed: profile, application, approved, goals
 * @param {object} programStats - The payload containing statistics related to a program (data frpom backend).
 * @param {unknown} module - Indicates whether the statistics relate to projects or mentors
 */

const StatsContainer = props => {
  const { appStart, appEnd, statsToShow, module } = props;
  const { t, format } = React.useContext(TranslationsContext);

  const programStats = useSelector(programMemberStatsSelectors.getMemberStatsOverview);

  return (
    <>
      <Row
        style={{
          margin: '10px',
          display: 'flex',
          width: '120%',
          flexWrap: 'nowrap',
        }}
      >
        {statsToShow.application && (
          <Col style={{ padding: '10px' }}>
            <ApplicationStats data={programStats?.application} module={module} t={t} />
          </Col>
        )}
        {statsToShow.approved && (
          <Col style={{ padding: '10px' }}>
            <ApprovedStats data={programStats} module={module} t={t} />
          </Col>
        )}
        {statsToShow.goals && (
          <Col style={{ padding: '10px' }}>
            <GoalStats data={programStats} t={t} />
          </Col>
        )}
        <Col style={{ padding: '10px' }}>
          <PeriodInfo
            title={t('Application Period')}
            startDate={appStart}
            endDate={appEnd}
            subtext={format(t('An auto reminder email will be sent on %(emailDate)s'), {
              emailDate: appEnd,
            })}
          />
        </Col>
      </Row>
    </>
  );
};

StatsContainer.propTypes = {
  appStart: PropTypes.number,
  appEnd: PropTypes.number,
  statsToShow: PropTypes.instanceOf(Object).isRequired,
  programStats: PropTypes.instanceOf(Object).isRequired,
  module: PropTypes.string,
};

StatsContainer.defaultProps = {
  appStart: null,
  appEnd: null,
  module: '',
};

export default StatsContainer;
