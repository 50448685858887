// Libraries
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Actions and Selectors
import { actions as authActions, selectors as userSelectors } from 'store/modules/user';

// Components
import { Button, Form, Modal, PasswordInput } from 'components/elements';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import { P } from 'components/fontSystem';

// Variables
const CURRENT_PASSWORD = 'current_password';
const INPUT_PASS = 'new_password';
const INPUT_CONFIRM_PASS = 're_new_password';

const CurrentPasswordModal = ({ open, onClose, handleRefreshUserData, ...props }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useContext(TranslationsContext);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { setPassword } = authActions;

  const user = useSelector(userSelectors.getUser);

  const handleResetPassword = () => {
    dispatch(setPassword({ user: user.username, ...form.getFieldsValue() }, handleRefreshUserData));
  };

  const ResetPasswordButton = (
    <Button
      type="primary"
      onClick={handleResetPassword}
      htmlType="submit"
      disabled={isButtonDisabled}
    >
      {t('Reset Password')}
    </Button>
  );

  const handleOnValuesChange = () => {
    setIsButtonDisabled(
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length > 0)
    );
  };

  return (
    <StyledModal
      width={600}
      open={open}
      onCancel={onClose}
      title={t('Update your Password')}
      footer={ResetPasswordButton}
      destroyOnClose
      {...props}
    >
      <ModalBody>
        <Form form={form} onFieldsChange={handleOnValuesChange}>
          <P>{t('Enter your current password in order to set a new one.')}</P>
          <Form.Item
            noAsterisk
            label={t('Current password')}
            size="large"
            name={CURRENT_PASSWORD}
            displayBlock
            rules={[
              {
                required: true,
                message: t('Enter your password here'),
              },
            ]}
          >
            <PasswordInput placeholder={t('Enter current password')} name={CURRENT_PASSWORD} />
          </Form.Item>
          <Form.Item
            noAsterisk
            label={t('New password')}
            size="large"
            name={INPUT_PASS}
            displayBlock
            rules={[
              {
                required: true,
                message: t('Enter your password here'),
              },
              {
                min: 8,
                message: t('Your password is too short!'),
              },
            ]}
          >
            <PasswordInput
              placeholder={t('Enter new password (min. 8 characters)')}
              name={INPUT_PASS}
            />
          </Form.Item>
          <Form.Item
            noAsterisk
            label={t('Confirm new password')}
            size="large"
            name={INPUT_CONFIRM_PASS}
            dependencies={[INPUT_PASS]} // Validate only if INPUT_PASS changes
            displayBlock
            rules={[
              { required: true, message: t('Enter your password here') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(INPUT_PASS) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('Passwords do not match!')));
                },
              }),
            ]}
          >
            <PasswordInput placeholder={t('Enter new password again')} name={INPUT_PASS} />
          </Form.Item>
        </Form>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-bottom: 0;
  }
  .ant-modal-body {
    padding-top: 3px;
  }
`;
const ModalBody = styled.div`
  p {
    padding-bottom: 30px;
  }
`;

export default CurrentPasswordModal;
