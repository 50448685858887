import { combineReducers } from 'redux';

// Import reducers
import activity from './modules/BusinessModelsMarketing/activity';
import areasOfExpertise from './modules/areasOfExpertise';
import attribute from './modules/CompetitionMap/attribute';
import behavior from './modules/GrowthPlan/behavior';
import beneficiary from './modules/ImpactPlan/beneficiary';
import beneficiaryTypes from './modules/ImpactPlan/beneficiaryTypes';
import benefit from './modules/ValueProposition/benefit';
import businessModel from './modules/BusinessModelsMarketing/businessModel';
import chain from './modules/StakeholderMap/chain';
import channel from './modules/StakeholderMap/channel';
import comment from './modules/comment';
import competitor from './modules/CompetitionMap/competitor';
import customization from './modules/customization';
import deepdive from './modules/DeepDive';
import deepDiveLink from './modules/DeepDive/link';
import demoExamples from './modules/demoExamples';
import educationExperience from './modules/educationExperience';
import emailDashboardMember from './modules/EmailDashboardMember';
import emailDashboardMentor from './modules/EmailDashboardMentor';
import executiveSummary from './modules/executiveSummary';
import membersExecutiveSummary from './modules/executiveSummary/members';
import expense from './modules/FinancialProjections/expense';
import feature from './modules/CompetitionMap/feature';
import financialProduct from './modules/FinancialProjections/financialProduct';
import financialProjection from './modules/FinancialProjections/financialProjection';
import fixedAsset from './modules/FinancialProjections/fixedAsset';
import funding from './modules/FinancialProjections/funding';
import growthRisk from './modules/GrowthPlan/growthRisk';
import initiative from './modules/GrowthPlan/initiative';
import interestship from './modules/Matching/interestship';
import invites from './modules/Invites';
import investment from './modules/FinancialProjections/investment';
import IPresources from './modules/ImpactPlan/resource';
import keyResult from './modules/GrowthPlan/keyResult';
import member from './modules/member';
import memberApplication from './modules/memberApplication';
import mentor from './modules/mentor';
import mentorApplication from './modules/mentorApplication';
import metric from './modules/GrowthPlan/metric';
import milestone from './modules/GrowthPlan/milestone';
import moonshotGoal from './modules/GrowthPlan/moonshotGoal';
import notifications from './modules/notifications';
import objective from './modules/GrowthPlan/objective';
import organization from './modules/organization';
import outcome from './modules/ValueProposition/outcome';
import pain from './modules/ValueProposition/pain';
import parrobot from './modules/parrobot';
import partner from './modules/StakeholderMap/partner';
import payroll from './modules/FinancialProjections/payroll';
import pills from './modules/Pills';
import pillsCategories from './modules/PillsCategories';
import pillsToolkit from './modules/PillsToolkit';
import positioning from './modules/CompetitionMap/positioning';
import pricingModel from './modules/PricingBusinessViability/pricingModel';
import problem from './modules/ValueProposition/problem';
import product from './modules/product';
import profilePicture from './modules/profilePicture';
import program from './modules/program';
import programSetup from './modules/programSetup';
import programMembers from './modules/programMembers';
import programMentors from './modules/programMentors';
import programTools from './modules/programTools';
import programStats from './modules/programStats';
import programStep from './modules/programStep';
import project from './modules/project';
import projectLink from './modules/projectLink';
import projectSdg from './modules/ImpactPlan/projectSdg';
import reason from './modules/ValueProposition/reason';
import relationships from './modules/ImpactPlan/relationship';
import resources from './modules/StakeholderMap/resources';
import result from './modules/ImpactPlan/result';
import retentionActivity from './modules/PricingBusinessViability/retentionActivity';
import riskCategory from './modules/GrowthPlan/riskCategory';
import role from './modules/ImpactPlan/role';
import rules from './modules/ImpactPlan/rule';
import organizationalRole from './modules/GrowthPlan/organizationalRole';
import scenario from './modules/scenario';
import sdg from './modules/ImpactPlan/sdg';
import sdgOutcome from './modules/ImpactPlan/outcome';
import sdgOutcomeIndicator from './modules/ImpactPlan/outcomeIndicator';
import sdgTarget from './modules/ImpactPlan/sdgTarget';
import sector from './modules/sector';
import segment from './modules/ValueProposition/segment';
import statusSummary from './modules/statusSummary';
import step from './modules/Tools/step';
import surveys from './modules/surveys';
import memberSurveys from './modules/memberSurveys';
import surveysResponses from './modules/surveyResponse';
import system from './modules/ImpactPlan/system';
import tool from './modules/Tools/tool';
import translations from './modules/translations';
import user from './modules/user';
import userLink from './modules/userLink';
import value from './modules/GrowthPlan/value';
import workExperience from './modules/workExperience';
import managementPanelMember from './modules/ManagementPanelMember';
import managementPanelMentor from './modules/ManagementPanelMentor';
import glossary from './modules/glossary';

// Tool PDF Stores
import valuePropositionPdf from './modules/toolsPdf/ValueProposition';
import stakeholderMapPdf from './modules/toolsPdf/StakeholderMap';
import competitionMapPdf from './modules/toolsPdf/CompetitionMap';
import businessModelsMarketingPdf from './modules/toolsPdf/BusinessModelsMarketing';
import pricingBusinessViabilityPdf from './modules/toolsPdf/PricingBusinessViability';
import financialProjectionsPdf from './modules/toolsPdf/FinancialProjections';
import impactPlanPdf from './modules/toolsPdf/ImpactPlan';
import growthPlanPdf from './modules/toolsPdf/GrowthPlan';

// Redux Toolkit - eventually all the reducers from above should be listed below
// API Slices
import { adminsApi } from 'services/apiSlices/adminsApi';
import { adminDashboardApi } from 'services/apiSlices/adminDashboardApi';
import { adminDashboardProgramsApi } from 'services/apiSlices/programs/adminDashboardProgramsApi';
import { areasOfExpertiseApi } from 'services/apiSlices/areasOfExpertiseApi';
import { areasOfInnovationApi } from 'services/apiSlices/areasOfInnovationApi';
import { countriesApi, publicCountriesApi, regionsAPI } from 'services/apiSlices/countriesApi';
import { entrepreneursApi } from 'services/apiSlices/entrepreneurApi';
import { entrepreneurApplicationApi } from 'services/apiSlices/applications/entrepreneurApplicationApi';
import { exploreProjectsApi } from 'services/apiSlices/projects/exploreProjectsApi';
import { interestshipsApi } from 'services/apiSlices/interestshipsApi';
import { languagesApi } from 'services/apiSlices/languagesApi';
import { communityProfilesApi } from 'services/apiSlices/communityProfilesApi';
import { userApi } from 'services/apiSlices/userApi';
import { managementPanelApi } from 'services/apiSlices/managementPanelApi';
import { mentorApplicationApi } from 'services/apiSlices/applications/mentorApplicationApi';
import { organizationsApi } from 'services/apiSlices/organizationsApi';
import { projectsApi } from 'services/apiSlices/projects/projectsApi';
import { programsApi } from 'services/apiSlices/programs/programsApi';
import { programsDashboardApi } from 'services/apiSlices/programsDashboardApi';
import { publicProgramsApi } from 'services/apiSlices/programs/publicProgramsApi';
import { publicProjectsApi } from 'services/apiSlices/projects/publicProjectsApi';
import { parrobotChatApi } from 'services/apiSlices/parrobotChat/parrobotChatApi';
import { sectorsApi } from 'services/apiSlices/sectorsApi';
import { pillsApi } from 'services/apiSlices/pillsApi';
import { pillToolsApi } from 'services/apiSlices/pillToolsApi';
import { pillCategoriesApi } from 'services/apiSlices/pillCategoriesApi';
import { userTokenAPI } from 'services/apiSlices/userTokenApi';
import { demoTokenAPI } from 'services/apiSlices/demoTokenApi';
import { teamApi } from 'services/apiSlices/teamApi';

// Non-API Slices
import { applicationEntrepreneurReducer } from './modules/application/applicationEntrepreneurSlice';
import { applicationMentorReducer } from './modules/application/applicationMentorSlice';
import { ventureSummaryReducer } from './modules/application/ventureSummarySlice';
import { projectFinancialForecastReducer } from './modules/application/financialForecastSlice';
import applicationEntrepreneurSectionsReducer from 'store/modules/application/entrepreneurApplicationSectionsSlice';
import applicationMentorSectionsReducer from './modules/application/mentorApplicationSectionsSlice';
import { languagesReducer } from './modules/languages/languagesSlice';

const rootReducer = combineReducers({
  activity,
  areasOfExpertise,
  attribute,
  behavior,
  beneficiary,
  beneficiaryTypes,
  benefit,
  businessModel,
  chain,
  channel,
  comment,
  competitor,
  customization,
  deepdive,
  deepDiveLink,
  demoExamples,
  educationExperience,
  emailDashboardMember,
  emailDashboardMentor,
  executiveSummary,
  membersExecutiveSummary,
  expense,
  feature,
  financialProduct,
  financialProjection,
  fixedAsset,
  funding,
  growthRisk,
  initiative,
  interestship,
  investment,
  IPresources,
  keyResult,
  member,
  memberSurveys,
  surveysResponses,
  memberApplication,
  mentor,
  mentorApplication,
  metric,
  milestone,
  moonshotGoal,
  notifications,
  objective,
  organization,
  outcome,
  pain,
  parrobot,
  partner,
  invites,
  payroll,
  pills,
  pillsCategories,
  pillsToolkit,
  positioning,
  pricingModel,
  problem,
  product,
  profilePicture,
  program,
  programSetup,
  programMembers,
  programMentors,
  programTools,
  programStats,
  programStep,
  project,
  projectLink,
  projectSdg,
  reason,
  relationships,
  resources,
  result,
  retentionActivity,
  riskCategory,
  role,
  rules,
  organizationalRole,
  scenario,
  sdg,
  sdgOutcome,
  sdgOutcomeIndicator,
  sdgTarget,
  sector,
  segment,
  statusSummary,
  step,
  surveys,
  system,
  tool,
  translations,
  user,
  userLink,
  value,
  workExperience,
  managementPanelMember,
  managementPanelMentor,
  glossary,
  // Tool PDF Stores
  valuePropositionPdf,
  stakeholderMapPdf,
  competitionMapPdf,
  businessModelsMarketingPdf,
  pricingBusinessViabilityPdf,
  financialProjectionsPdf,
  impactPlanPdf,
  growthPlanPdf,
  // API Slices
  [adminsApi.reducerPath]: adminsApi.reducer,
  [adminDashboardApi.reducerPath]: adminDashboardApi.reducer,
  [adminDashboardProgramsApi.reducerPath]: adminDashboardProgramsApi.reducer,
  [areasOfExpertiseApi.reducerPath]: areasOfExpertiseApi.reducer,
  [areasOfInnovationApi.reducerPath]: areasOfInnovationApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [publicCountriesApi.reducerPath]: publicCountriesApi.reducer,
  [regionsAPI.reducerPath]: regionsAPI.reducer,
  [entrepreneursApi.reducerPath]: entrepreneursApi.reducer,
  [entrepreneurApplicationApi.reducerPath]: entrepreneurApplicationApi.reducer,
  [exploreProjectsApi.reducerPath]: exploreProjectsApi.reducer,
  [interestshipsApi.reducerPath]: interestshipsApi.reducer,
  [languagesApi.reducerPath]: languagesApi.reducer,
  [communityProfilesApi.reducerPath]: communityProfilesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [managementPanelApi.reducerPath]: managementPanelApi.reducer,
  [mentorApplicationApi.reducerPath]: mentorApplicationApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [programsApi.reducerPath]: programsApi.reducer,
  [programsDashboardApi.reducerPath]: programsDashboardApi.reducer,
  [publicProgramsApi.reducerPath]: publicProgramsApi.reducer,
  [publicProjectsApi.reducerPath]: publicProjectsApi.reducer,
  [parrobotChatApi.reducerPath]: parrobotChatApi.reducer,
  [sectorsApi.reducerPath]: sectorsApi.reducer,
  [pillsApi.reducerPath]: pillsApi.reducer,
  [pillToolsApi.reducerPath]: pillToolsApi.reducer,
  [pillCategoriesApi.reducerPath]: pillCategoriesApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [userTokenAPI.reducerPath]: userTokenAPI.reducer,
  [demoTokenAPI.reducerPath]: demoTokenAPI.reducer,
  // Custom Slices (not API)
  applicationEntrepreneurSections: applicationEntrepreneurSectionsReducer,
  applicationMentorSections: applicationMentorSectionsReducer,
  applicationMentor: applicationMentorReducer, // calling it mentorApplication will throw a duplicate key error since we already have a mentorApplication above
  applicationEntrepreneur: applicationEntrepreneurReducer,
  ventureSummary: ventureSummaryReducer,
  FinancialForecast: projectFinancialForecastReducer,
  languages: languagesReducer,
});

export default rootReducer;
