import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

const TEAM_TAG = 'Team';

// API module for project team
export const teamApi = createApi({
  reducerPath: 'teamApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/invites_member/',
  }),
  tagTypes: [TEAM_TAG],
  endpoints: builder => ({
    // Fetch the team invites
    getTeamInvites: builder.query({
      query: () => '',
      providesTags: [TEAM_TAG],
    }),
    // Send a new team invite
    createTeamMemberInvite: builder.mutation({
      query: inviteData => ({
        method: 'POST',
        data: inviteData,
      }),
      invalidatesTags: [TEAM_TAG],
    }),
    // Update a team invite
    updateMemberInvite: builder.mutation({
      query: ({ id, ...inviteData }) => ({
        url: `${id}/`,
        method: 'PATCH',
        data: inviteData,
      }),
    }),
  }),
});

export const {
  useGetTeamInvitesQuery,
  useCreateTeamMemberInviteMutation,
  useUpdateMemberInviteMutation,
} = teamApi;
