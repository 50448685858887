import { Record } from 'immutable';

// Statuses
const STATUS_IN_PROGRESS = 0; // In Progress (Application)
const STATUS_PENDING_DECISION = 1; // Pending Decision (Selection)
const STATUS_APPROVED_NO_EMAIL = 2; // Approved - No email (Approved)
const STATUS_APPROVED_ONBOARDING_SESSION = 3; // Approved - Onboarding Session (Approved)
const STATUS_UNMATCHED = 4; // Un-matched (Matching)
const STATUS_MATCHED = 5; // Matched (Matching)
const STATUS_INCUBATING = 6; // Incubating (Incubation)
const STATUS_ALUMNI = 7; // Alumni (Alumni)

export const getStatusDisplayNewPMPanel = (t, status) => {
  const statusNames = {
    [STATUS_IN_PROGRESS]: t('In Progress'),
    [STATUS_PENDING_DECISION]: t('Pending Decision'),
    [STATUS_APPROVED_NO_EMAIL]: t('Manual Follow-Up'),
    [STATUS_APPROVED_ONBOARDING_SESSION]: t('Onboarding'),
    [STATUS_UNMATCHED]: t('Unmatched'),
    [STATUS_MATCHED]: t('Matched'),
    [STATUS_INCUBATING]: t('Incubating'),
    [STATUS_ALUMNI]: t('Completed'),
  };
  return statusNames[status];
};

// Program Management stages
export const MENTOR_STATUS_APPLICATION = 'application';
export const MENTOR_STATUS_SELECTION = 'selection';
export const MENTOR_STATUS_APPROVED = 'approved';
export const MENTOR_STATUS_MATCHING = 'matching';
export const MENTOR_STATUS_INCUBATION = 'incubating';
export const MENTOR_STATUS_ALUMNI = 'alumni';
export const MENTOR_STATUS_REJECTED = 'rejected';
export const MENTOR_STATUS_DROPPED = 'dropped';

export const MENTOR_STATUS_MAP = [
  MENTOR_STATUS_APPLICATION,
  MENTOR_STATUS_SELECTION,
  MENTOR_STATUS_APPROVED,
  MENTOR_STATUS_MATCHING,
  MENTOR_STATUS_INCUBATION,
  MENTOR_STATUS_ALUMNI,
  MENTOR_STATUS_REJECTED,
  MENTOR_STATUS_DROPPED,
];

export const getPMStageByApplicationStatus = status => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return MENTOR_STATUS_APPLICATION;
    case STATUS_PENDING_DECISION:
      return MENTOR_STATUS_SELECTION;
    case STATUS_APPROVED_NO_EMAIL:
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return MENTOR_STATUS_APPROVED;
    case STATUS_MATCHED:
    case STATUS_UNMATCHED:
      return MENTOR_STATUS_MATCHING;
    case STATUS_INCUBATING:
      return MENTOR_STATUS_INCUBATION;
    case STATUS_ALUMNI:
      return MENTOR_STATUS_ALUMNI;
    default:
      return undefined;
  }
};

// TODO: Everywhere where we export Drop, Reject and Accept options, we turn them from objects to arrays.
// This is because we need to map over them in the UI.
// We should consider changing the UI to use objects instead of arrays. Or we can export these options as arrays instead of objects.

// Dropped reasons
const DROP_UNRESPONSIVE = 0;
const DROP_NOT_A_FIT = 1;
const DROP_BAD_EXPERIENCE_PM = 2;
const DROP_BAD_EXPERIENCE_E = 3;
const DROP_BAD_TIMING = 4;
const DROP_NOT_INTERESTED_PROJECTS = 5;
const DROP_REJECTED_BY_E = 6;
const DROP_FOR_PROJECT_DROPPED = 7;

export const getDroppedReasonDisplay = (t, droppedReason) => {
  switch (droppedReason) {
    case DROP_UNRESPONSIVE:
      return t('Became unresponsive');
    case DROP_NOT_A_FIT:
      return t('Not a fit');
    case DROP_BAD_EXPERIENCE_PM:
      return t('Had a bad experience with Program Manager');
    case DROP_BAD_EXPERIENCE_E:
      return t('Had a bad experience with Entrepreneur');
    case DROP_BAD_TIMING:
      return t('Bad timing');
    case DROP_NOT_INTERESTED_PROJECTS:
      return t('No interest in projects');
    case DROP_REJECTED_BY_E:
      return t('Was rejected by Entrepreneur(s)');
    case DROP_FOR_PROJECT_DROPPED:
      return t('The project was dropped');
    default:
      return undefined;
  }
};

const getBecameUnresponsive = t => ({
  value: DROP_UNRESPONSIVE,
  text: getDroppedReasonDisplay(t, DROP_UNRESPONSIVE),
});

const getNotAFit = t => ({
  value: DROP_NOT_A_FIT,
  text: getDroppedReasonDisplay(t, DROP_NOT_A_FIT),
});

const getHadBadExperienceWithPM = t => ({
  value: DROP_BAD_EXPERIENCE_PM,
  text: getDroppedReasonDisplay(t, DROP_BAD_EXPERIENCE_PM),
});

const getHadBadExperienceWithE = t => ({
  value: DROP_BAD_EXPERIENCE_E,
  text: getDroppedReasonDisplay(t, DROP_BAD_EXPERIENCE_E),
});

const getBadTiming = t => ({
  value: DROP_BAD_TIMING,
  text: getDroppedReasonDisplay(t, DROP_BAD_TIMING),
});

const getNoInterestInProjects = t => ({
  value: DROP_NOT_INTERESTED_PROJECTS,
  text: getDroppedReasonDisplay(t, DROP_NOT_INTERESTED_PROJECTS),
});

const getRejectedByE = t => ({
  value: DROP_REJECTED_BY_E,
  text: getDroppedReasonDisplay(t, DROP_REJECTED_BY_E),
});

const getProjectWasDropped = t => ({
  value: DROP_FOR_PROJECT_DROPPED,
  text: getDroppedReasonDisplay(t, DROP_FOR_PROJECT_DROPPED),
});

export const getMentorDropOptions = t => ({
  BECAME_UNRESPONSIVE: getBecameUnresponsive(t),
  NOT_A_FIT: getNotAFit(t),
  BAD_EXPERIENCE_PM: getHadBadExperienceWithPM(t),
  BAD_EXPERIENCE_E: getHadBadExperienceWithE(t),
  BAD_TIMING: getBadTiming(t),
  NO_INTEREST_IN_PROJECTS: getNoInterestInProjects(t),
  REJECTED_BY_E: getRejectedByE(t),
  PROJECT_DROPPED: getProjectWasDropped(t),
});

// Rejected reasons
const REJ_INCOMPLETE = 0;
const REJ_PROF_EXPERIENCE = 1;
const REJ_ENT_KNOWLEDGE = 2;
const REJ_LAN_PROFICIENCY = 3;
const REJ_PROGRAM_REQUIREMENTS = 4;
const REJ_OTHER = 5;

export const getRejectedStatusDisplay = (t, rejectedReason) => {
  switch (rejectedReason) {
    case REJ_INCOMPLETE:
      return t('Incomplete / weak application');
    case REJ_PROF_EXPERIENCE:
      return t('Lack of professional experience');
    case REJ_ENT_KNOWLEDGE:
      return t('Lack of entrepreneurship knowledge');
    case REJ_LAN_PROFICIENCY:
      return t('Lack of language proficiency');
    case REJ_PROGRAM_REQUIREMENTS:
      return t('Does not meet program requirements');
    case REJ_OTHER:
      return t('Other (no email sent)');
    default:
      return undefined;
  }
};

const getIncomplete = t => ({
  value: REJ_INCOMPLETE,
  text: getRejectedStatusDisplay(t, REJ_INCOMPLETE),
});

const getLackOfProfessionalExperience = t => ({
  value: REJ_PROF_EXPERIENCE,
  text: getRejectedStatusDisplay(t, REJ_PROF_EXPERIENCE),
});

const getLackOfEntrepreneurshipKnowledge = t => ({
  value: REJ_ENT_KNOWLEDGE,
  text: getRejectedStatusDisplay(t, REJ_ENT_KNOWLEDGE),
});

const getLackOfLanguageProficiency = t => ({
  value: REJ_LAN_PROFICIENCY,
  text: getRejectedStatusDisplay(t, REJ_LAN_PROFICIENCY),
});

const getDoesNotMeetRequirements = t => ({
  value: REJ_PROGRAM_REQUIREMENTS,
  text: getRejectedStatusDisplay(t, REJ_PROGRAM_REQUIREMENTS),
});

const getOther = t => ({
  value: REJ_OTHER,
  text: getRejectedStatusDisplay(t, REJ_OTHER),
});

export const getMentorRejectedOptions = t => ({
  INCOMPLETE: getIncomplete(t),
  PROF_EXPERIENCE: getLackOfProfessionalExperience(t),
  ENT_KNOWLEDGE: getLackOfEntrepreneurshipKnowledge(t),
  LAN_PROFICIENCY: getLackOfLanguageProficiency(t),
  PROGRAM_REQUIREMENTS: getDoesNotMeetRequirements(t),
  OTHER: getOther(t),
});

// Accept/Move to a stage
export const getAcceptOptionDisplay = (t, status) => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return t('Move to Application');
    case STATUS_PENDING_DECISION:
      return t('Move to Selection');
    case STATUS_APPROVED_NO_EMAIL:
      return t('Accept, but no email sent');
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return t('Accept & Attend Onboarding Session');
    case STATUS_UNMATCHED:
      return t('Accept & move directly to MATCHING');
    default:
      return undefined;
  }
};

const getSelectionOption = t => ({
  value: STATUS_PENDING_DECISION,
  text: getAcceptOptionDisplay(t, STATUS_PENDING_DECISION),
});

const getFollowUpOption = t => ({
  value: STATUS_APPROVED_NO_EMAIL,
  text: getAcceptOptionDisplay(t, STATUS_APPROVED_NO_EMAIL),
});

const getApprovedOption = t => ({
  value: STATUS_APPROVED_ONBOARDING_SESSION,
  text: getAcceptOptionDisplay(t, STATUS_APPROVED_ONBOARDING_SESSION),
});

const getMatchingOption = t => ({
  value: STATUS_UNMATCHED,
  text: getAcceptOptionDisplay(t, STATUS_UNMATCHED),
});

export const getMentorAcceptedOptions = t => ({
  SELECTION: getSelectionOption(t),
  APPROVED: getFollowUpOption(t),
  APPROVED_ONBOARDING: getApprovedOption(t),
  MATCHING: getMatchingOption(t),
});

const MentorApplicationRecord = Record({
  id: null,
  mentor: null,
  program: null,
  additional_answer_1: null,
  additional_answer_2: null,
  additional_answer_3: null,
  additional_answer: null,
  complete_surveys: null,
  notes: null,
  matching_notes: '',
  application_notes: '',
  dropped_notes: '',
  incubating_notes: '',
  last_follow_up_date: null,
  participate_in_another_program_if_rejected: null,
  program_type: null,
  progress: null,
  ranking_max_projects: null,
  invites_pending_sign: 0,
  ranking_open_to_comentor: null,
  ranking_reasons: null,
  ranking_submitted: null,
  ranking: null,
  rejected: null,
  remark: null,
  review_status: null,
  STATUS_APPROVED_NO_EMAIL,
  STATUS_ALUMNI,
  STATUS_IN_PROGRESS,
  STATUS_INCUBATING,
  STATUS_MATCHED,
  STATUS_PENDING_DECISION,
  STATUS_APPROVED_ONBOARDING_SESSION,
  STATUS_UNMATCHED,
  status: null,
  survey_responses_eoi_ment: null,
  survey_responses_pc_ment: null,
});

class MentorApplication extends MentorApplicationRecord {
  // Statuses
  static STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  static STATUS_PENDING_DECISION = STATUS_PENDING_DECISION;
  static STATUS_APPROVED_NO_EMAIL = STATUS_APPROVED_NO_EMAIL;
  static STATUS_APPROVED_ONBOARDING_SESSION = STATUS_APPROVED_ONBOARDING_SESSION;
  static STATUS_UNMATCHED = STATUS_UNMATCHED;
  static STATUS_MATCHED = STATUS_MATCHED;
  static STATUS_INCUBATING = STATUS_INCUBATING;
  static STATUS_ALUMNI = STATUS_ALUMNI;

  // Rejected reasons
  static REJ_INCOMPLETE = REJ_INCOMPLETE;
  static REJ_PROF_EXPERIENCE = REJ_PROF_EXPERIENCE;
  static REJ_ENT_KNOWLEDGE = REJ_ENT_KNOWLEDGE;
  static REJ_LAN_PROFICIENCY = REJ_LAN_PROFICIENCY;
  static REJ_PROGRAM_REQUIREMENTS = REJ_PROGRAM_REQUIREMENTS;
  static REJ_OTHER = REJ_OTHER;

  get id() {
    return this.get('id');
  }

  getRejectedStatusDisplay = t => getRejectedStatusDisplay(t, this.get('rejected')); // Not used anywhere right now
}

export default MentorApplication;
