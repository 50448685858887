import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

const PILL_CATEGORIES_TAG = 'PillCategories';

// API module for pill categories.
export const pillCategoriesApi = createApi({
  reducerPath: 'pillCategoriesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/pill-categories/',
  }),
  tagTypes: [PILL_CATEGORIES_TAG],
  endpoints: builder => ({
    // List all pill categories
    listPillCategories: builder.query({
      query: query => ({ params: query }),
      providesTags: [PILL_CATEGORIES_TAG],
    }),
    // Create a pill category
    createPillCategory: builder.mutation({
      query: data => ({
        method: 'POST',
        data,
      }),
      invalidatesTags: [PILL_CATEGORIES_TAG],
    }),
    // Update a pill category
    updatePillCategory: builder.mutation({
      query: ({ categoryId, data }) => ({
        method: 'PATCH',
        url: `${categoryId}/`,
        data,
      }),
      invalidatesTags: [PILL_CATEGORIES_TAG],
    }),
    // Delete a pill category
    deletePillCategory: builder.mutation({
      query: categoryId => ({
        url: `${categoryId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [PILL_CATEGORIES_TAG],
    }),
  }),
});

export const {
  useListPillCategoriesQuery,
  useCreatePillCategoryMutation,
  useUpdatePillCategoryMutation,
  useDeletePillCategoryMutation,
} = pillCategoriesApi;
