const USER_ACCESSIBILITY = 'bfb/user/USER_ACCESSIBILITY';
const USER_CLEAR_ERROR = 'bfb/user/USER_CLEAR_ERROR';
const USER_DISMISS_GETTING_STARTED = 'bfb/user/USER_DISMISS_STARTED';
const USER_EDIT = 'bfb/user/USER_EDIT';
const USER_EDIT_FAILED = 'bfb/user/USER_EDIT_FAILED';
const USER_EDIT_SUCCEEDED = 'bfb/user/USER_EDIT_SUCCEEDED';
const USER_FETCH_FAILED = 'bfb/user/USER_FETCH_FAILED';
const USER_FETCH_REQUESTED = 'bfb/user/USER_FETCH_REQUESTED';
const USER_FETCH_SUCCEEDED = 'bfb/user/USER_FETCH_SUCCEEDED';
const USER_FORGOT_PASSWORD = 'bfb/user/USER_FORGOT_PASSWORD';
const USER_FORGOT_PASSWORD_FAILED = 'bfb/user/USER_FORGOT_PASSWORD_FAILED';
const USER_FORGOT_PASSWORD_SUCCEEDED = 'bfb/user/USER_FORGOT_PASSWORD_SUCCEEDED';
const USER_LOGIN = 'bfb/user/USER_LOGIN';
const USER_REGISTER = 'bfb/user/USER_REGISTER';
const USER_REGISTER_FAILED = 'bfb/user/USER_REGISTER_FAILED';
const USER_REGISTER_SUCCEEDED = 'bfb/user/USER_REGISTER_SUCCEEDED';
const USER_RESET_PASSWORD = 'bfb/user/USER_RESET_PASSWORD';
const USER_RESET_PASSWORD_FAILED = 'bfb/user/USER_RESET_PASSWORD_FAILED';
const USER_RESET_PASSWORD_SUCCEEDED = 'bfb/user/USER_RESET_PASSWORD_SUCCEEDED';
const USER_SET_PASSWORD = 'bfb/user/USER_SET_PASSWORD';
const USER_SET_PASSWORD_FAILED = 'bfb/user/USER_SET_PASSWORD_FAILED';
const USER_SET_PASSWORD_SUCCEEDED = 'bfb/user/USER_SET_PASSWORD_SUCCEEDED';
const USER_RESTORE = 'bfb/user/USER_RESTORE';

export default {
  USER_ACCESSIBILITY,
  USER_CLEAR_ERROR,
  USER_DISMISS_GETTING_STARTED,
  USER_EDIT_FAILED,
  USER_EDIT_SUCCEEDED,
  USER_EDIT,
  USER_FETCH_FAILED,
  USER_FETCH_REQUESTED,
  USER_FETCH_SUCCEEDED,
  USER_FORGOT_PASSWORD_FAILED,
  USER_FORGOT_PASSWORD_SUCCEEDED,
  USER_FORGOT_PASSWORD,
  USER_LOGIN,
  USER_REGISTER_FAILED,
  USER_REGISTER_SUCCEEDED,
  USER_REGISTER,
  USER_RESET_PASSWORD_FAILED,
  USER_RESET_PASSWORD_SUCCEEDED,
  USER_RESET_PASSWORD,
  USER_RESTORE,
  USER_SET_PASSWORD,
  USER_SET_PASSWORD_FAILED,
  USER_SET_PASSWORD_SUCCEEDED,
};
