// Libraries
import styled, { css } from 'styled-components';

// Components
import { Tag } from 'antd';

// Dependencies
import MemberApplication from 'models/MemberApplication';
import MentorApplication from 'models/MentorApplication';
import bfbTheme from 'styles/b4b-theme';

const getBackgroundColor = ({ status }) => {
  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return 'rgb(253,184,51, 0.1)';
    case MemberApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
      return '#123DC610';
    case MemberApplication.STATUS_MATCHED:
    case MemberApplication.STATUS_INCUBATING:
      return '#37CC821A';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getBackgroundMentorColor = ({ status }) => {
  switch (status) {
    case MentorApplication.STATUS_IN_PROGRESS:
      return 'rgb(253,184,51, 0.1)';
    case MentorApplication.STATUS_PENDING_DECISION:
      return bfbTheme.crimsonBgFade;
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#123DC610';
    case MentorApplication.STATUS_MATCHED:
    case MentorApplication.STATUS_INCUBATING:
      return '#37CC821A';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getTextColor = ({ status }) => {
  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#FDB833';
    case MemberApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
    case MemberApplication.STATUS_MATCHED:
    case MemberApplication.STATUS_INCUBATING:
      return '#37CC82';
    default:
      return '#999999';
  }
};

const getTextMentorColor = ({ status }) => {
  switch (status) {
    case MentorApplication.STATUS_IN_PROGRESS:
      return '#FDB833';
    case MentorApplication.STATUS_PENDING_DECISION:
      return bfbTheme.crimson;
    case MentorApplication.STATUS_APPROVED_NO_EMAIL:
    case MentorApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#123DC6';
    case MentorApplication.STATUS_MATCHED:
    case MentorApplication.STATUS_INCUBATING:
      return '#37CC82';
    default:
      return '#999999';
  }
};

export const StyledAppMemberStatusTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundColor} !important;
  border-radius: 4px;
  border: ${({ status }) =>
    status === MemberApplication.STATUS_INCUBATING ? '1px solid #37CC82' : 'none'};
  color: ${getTextColor} !important;
  cursor: initial;
  display: inline-flex;
  font-size: 15px;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '2px 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
`;

export const StyledAppMentorStatusTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundMentorColor} !important;
  border-radius: 4px;
  border: ${({ status }) =>
    status === MentorApplication.STATUS_INCUBATING ? '1px solid #37CC82' : 'none'};
  color: ${getTextMentorColor} !important;
  cursor: initial;
  display: inline-flex;
  font-size: 15px;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '2px 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
`;
