import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing pills.
export const pillToolsApi = createApi({
  reducerPath: 'pillToolsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/pill-tools/',
  }),
  endpoints: builder => ({
    // List ALL sectors
    listPillTools: builder.query({
      query: query => ({ params: query }),
    }),
  }),
});

export const { useListPillToolsQuery } = pillToolsApi;
