import types from './types';

const clearError = () => ({
  type: types.RESOURCE_CLEAR_ERROR,
});

const createResource = ({ project, product, partner, ...resource }, callback) => ({
  type: types.RESOURCE_CREATE,
  payload: { project, product, partner, ...resource },
  callback,
});

const createResourceSucceeded = ({ partner, ...resource }) => ({
  type: types.RESOURCE_CREATE_SUCCEEDED,
  payload: { partner, ...resource },
});

const createResourceFailed = ({ message }) => ({
  type: types.RESOURCE_CREATE_FAILED,
  payload: { message },
});

const deleteResource = ({ project, product, partner, resourceId }, callback) => ({
  type: types.RESOURCE_DELETE,
  payload: { project, product, partner, resourceId },
  callback,
});

const deleteResourceSucceeded = payload => ({
  type: types.RESOURCE_DELETE_SUCCEEDED,
  payload,
});

const deleteResourceFailed = ({ message }) => ({
  type: types.RESOURCE_DELETE_FAILED,
  payload: { message },
});

const fetchResource = ({ project, product, partner, resourceId }) => ({
  type: types.RESOURCE_FETCH,
  payload: { project, product, partner, resourceId },
});

const fetchResourceSucceeded = ({ ...resource }) => ({
  type: types.RESOURCE_FETCH_SUCCEEDED,
  payload: { ...resource },
});

const fetchResourceFailed = ({ message }) => ({
  type: types.RESOURCE_FETCH_FAILED,
  payload: { message },
});

const listResources = ({ project, product, partner }) => ({
  type: types.RESOURCES_LIST,
  payload: { project, product, partner },
});

const listResourcesSucceeded = payload => ({
  type: types.RESOURCES_LIST_SUCCEEDED,
  payload,
});

const listResourcesFailed = ({ message }) => ({
  type: types.RESOURCES_LIST_FAILED,
  payload: { message },
});

const updateResource = ({ project, product, partner, resourceId, ...resource }) => ({
  type: types.RESOURCE_UPDATE,
  payload: { project, product, partner, resourceId, ...resource },
});

const updateResourceSucceeded = ({ ...resource }) => ({
  type: types.RESOURCE_UPDATE_SUCCEEDED,
  payload: { ...resource },
});

const updateResourceFailed = ({ message }) => ({
  type: types.RESOURCE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createResource,
  createResourceFailed,
  createResourceSucceeded,
  deleteResource,
  deleteResourceFailed,
  deleteResourceSucceeded,
  fetchResource,
  fetchResourceFailed,
  fetchResourceSucceeded,
  listResources,
  listResourcesFailed,
  listResourcesSucceeded,
  updateResource,
  updateResourceFailed,
  updateResourceSucceeded,
};
